<template>
  <div class="container">
    <b-row>
      <b-col>
        <h1>Profile {{ form.name }}</h1>
      </b-col>
    </b-row>
    <div class="row">
      <div class="col-sm-6 col-lg-6">
        <b-form-group>
          <label class="form-label">Name</label>
          <b-form-input
            v-model="form.name"
            required
            type="text"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-sm-6 col-lg-6">
        <b-form-group>
          <label class="form-label">Email</label>
          <b-form-input
            v-model="form.email"
            required
            type="email"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <button v-b-modal.change-password-modal class="btn btn-secondary mr-2">Change password</button>


    <div v-if="isWeb2UserConnected && getWeb2ActiveAccount"
         class="d-flex justify-content-end">
      <button class="btn btn-primary mr-2" @click.prevent="onSubmit">Save</button>
      <button class="btn btn-danger" @click="resetForm">Cancel</button>
    </div>

    <b-modal id="change-password-modal">
      <template #modal-title>
        Change password
      </template>
      <b-form-group>
        <b-form-input
          v-model="passData.password"
          placeholder="Enter password"
          required
          type="password"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-input
          v-model="passData.repeatPassword"
          placeholder="Repeat password"
          required
          type="password"
        ></b-form-input>
      </b-form-group>

      <template #modal-footer="{ ok, cancel, hide }">
        <b-button class="mr-2" variant="primary" @click="changePassword">
          OK
        </b-button>
        <b-button variant="secondary" @click="hide()">
          Close
        </b-button>

      </template>
    </b-modal>

  </div>

</template>

<script>

import User from '../../api/User'
// import {create, all} from 'mathjs'
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
// import LineChart from "../Chart/LineChart.js";
// import Swal from "sweetalert2";

export default {
  name: 'CustomerFormComponent',
  computed: {
    ...mapGetters("user", ["isWeb2UserConnected", "getWeb2ActiveAccount"]),
  },

  data() {
    return {
      passData: {
        password: null,
        repeatPassword: null,
      },
      form: {
        id: null,
        name: '',
        email: '',
        walletAddress: ''
      },
    }
  },
  created() {
    this.resetForm();

  },

  methods: {
    changePassword() {
      if (this.passData.password !== this.passData.repeatPassword) {
        Swal.fire('Error', 'Passwords does not match', 'error')
        return;
      }

      User.putMe({password: this.passData.password})
        .then(() => {
          this.$bvModal.hide('change-password-modal')

          this.$toasted.show('Your password has been update', {
            type: 'success',
            duration: 5000,
            theme: "bubble",
            position: "top-center"
          });
        }).catch((error) => {
        console.log(error)
        Swal.fire('Error', error.message, 'error')
      })


    },
    resetForm() {
      User.getMe()
        .then((response) => {
          this.form = response.data
          console.log({response})
        }).catch((error) => {
        console.log(error)
      })
    },
    onSubmit() {

      User.putMe({...this.form, username: this.form.email})
        .then(() => {
          this.$toasted.show('Your profile has been update', {
            type: 'success',
            duration: 5000,
            theme: "bubble",
            position: "top-center"
          });
        }).catch((error) => {
        console.log(error)
      })
    },

  }
}
</script>

<style scoped>

</style>
