<template>
  <b-container class="profile">
    <ProfileFormComponent/>
  </b-container>
</template>

<script>

import ProfileFormComponent from "@/components/User/ProfileFormComponent";

export default {
  name: 'Profile',

  components: {ProfileFormComponent}
}
</script>

<style scoped>

</style>
